




























































































































































































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import colors from 'vuetify/es5/util/colors';
import { trimStart } from 'lodash';
import { inject } from 'inversify-props';
import ClientModel from '@/models/crm/client.model';
import { ClientStatusEnum } from '@/enums/crm/client-status.enum';
import { StringHelper } from '@/utils/helpers/string-helper';
import CrmChatCallerButton from '@/components/crm/chat-caller-button.vue';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { OrigemTypeEnum } from '@/enums/origin-register-type.enum';
import SettingsModel from '@/models/crm/settings.model';
import ResponsibleAttendantModel from '@/models/crm/responsible-attendant.model';
import RouterService from '@/services/router.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import SessionService from '@/services/session.service';

interface IChipParam {
  id: string;
  text: string;
  icon: string;
  color: string;
}

@Component({
  components: {
    CrmChatCallerButton,
  },
})
export default class CrmClientDetailsCard extends Vue {
  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @Prop({ required: true })
  readonly client!: ClientModel;

  @Prop({ required: true })
  readonly clientType!: ClientTypeEnum;

  @Prop({ required: true })
  readonly origemType!: OrigemTypeEnum;

  @Prop()
  readonly settings!: SettingsModel;

  @Prop()
  readonly actionsLoading!: boolean;

  @Prop()
  readonly clientManagementSituation!: string;

  @Prop()
  readonly attendantsList!: ResponsibleAttendantModel[];

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Emit('send-email:click')
  onSendEmail(): ClientModel {
    return this.client;
  }

  @Emit('edit-prospect')
  onEditProspect(): ClientModel {
    return this.client;
  }

  @Emit('delete-prospect')
  onDeleteProspect(): ClientModel {
    return this.client;
  }

  @Emit('transforme-prospect')
  onTransformProspect(): ClientModel {
    return this.client;
  }

  @Emit('open-particularities')
  onOpenParticularities(): ClientModel {
    return this.client;
  }

  @Emit('set-responsible')
  // eslint-disable-next-line class-methods-use-this
  onSetResponsibleAttendant(responsibleAttendant: number): number | null {
    // @ts-expect-error TS não estava permitindo o acesso à chamada do evento;
    this.$refs.responsibleAttendantSelect.blur();
    return responsibleAttendant;
  }

  get isClientType(): boolean {
    return this.clientType === ClientTypeEnum.Client;
  }

  get isProspectType(): boolean {
    return this.clientType === ClientTypeEnum.Prospect;
  }

  get isOrigemNaoDefinida(): boolean {
    return this.client.origemCadastro === OrigemTypeEnum.SEMORIGEM || this.origemType === null;
  }

  get isOrigemCrm(): boolean {
    return this.client.origemCadastro === OrigemTypeEnum.CRM;
  }

  get isOrigemB2B(): boolean {
    return this.client.origemCadastro === OrigemTypeEnum.B2B;
  }

  get isOrigemForcaVendas(): boolean {
    return this.client.origemCadastro === OrigemTypeEnum.FORCAVENDAS;
  }

  get showTransformProspectBtn(): boolean {
    return this.isProspectType && this.settings?.flagConverteProspectIGS && !this.isConvertedProspect;
  }

  get address(): string {
    let address = this.client.endRua || '';
    if (this.client.endNumero) {
      address += `, ${this.client.endNumero}`;
    }
    if (this.client.endBairro) {
      address += ` - ${this.client.endBairro}`;
    }
    if (this.client.endComplemento) {
      address += ` - ${this.client.endComplemento}`;
    }
    if (this.client.endCEP) {
      address += ` - ${this.$t('crm.clientDetailsCard.postalCode')}: ${this.client.endCEP}`;
    }

    return trimStart(address, ' -,');
  }

  get chips(): IChipParam[] {
    if (!this.client || !this.client.cnpjCpf) {
      return [];
    }

    const availableChips: IChipParam[] = [];

    if (this.client.inadimplente) {
      availableChips.push({
        id: 'defaulting',
        text: `${this.$t('crm.clientDetailsCard.defaulting')} - ${this.client.diasInadimplente}`,
        icon: 'mdi-thumb-down-outline',
        color: 'red',
      });
    }

    if (this.clientManagementSituation) {
      availableChips.push({
        id: 'clientManagementSituation',
        text: this.clientManagementSituation,
        icon: 'mdi-account-outline',
        color: '#f47304',
      });
    }

    if (this.client.limiteCredito || this.client.limiteCredito === 0) {
      availableChips.push({
        id: 'creditLimit',
        text: `${this.$t('crm.clientDetailsCard.availableCredit')} - ${this.$n(this.client.limiteCredito, 'currency')}`,
        icon: 'mdi-currency-usd',
        color: this.client.limiteCredito < 0 ? 'red' : 'green',
      });
    }

    if (this.client.listClientesGrupo && this.client.listClientesGrupo.length > 1) {
      this.client.listClientesGrupo.forEach((group) => {
        availableChips.push({
          id: `group_${group.codGrupo}`,
          text: group.nomeGrupo,
          icon: 'mdi-account-group',
          color: 'black',
        });
      });
    } else if (this.client.codGrupoEconomico && this.client.nomeGrupoEconomico) {
      availableChips.push({
        id: `${this.client.codGrupoEconomico}`,
        text: `Nome do Grupo - ${this.client.nomeGrupoEconomico}`,
        icon: 'mdi-account-group',
        color: 'green',
      });
    }
    return availableChips;
  }

  get nameInitials(): string {
    return StringHelper.extractInitialsName(this.client.nomeFantasia || this.client.nome).toUpperCase();
  }

  get statusColor(): string {
    switch (this.client.situacao) {
      case ClientStatusEnum.Inactive:
      case ClientStatusEnum.ConversionFailed:
        return colors.red.base;
      case ClientStatusEnum.Converted:
        return '#00dbc6';
      case ClientStatusEnum.Active:
      default:
        return colors.green.base;
    }
  }

  get cnpjCpfMask(): string {
    return this.client.cnpjCpf && this.client.cnpjCpf.length > 11 ? '##.###.###/####-##' : '###.###.###-##';
  }

  get celularMask(): string | null {
    const phone = (this.cellPhone || '').replace(/\D/g, '');
    if (phone.length === 10) {
      return '(##) ####-####';
    }

    if (phone.length === 11) {
      return '(##) #####-####';
    }

    return null;
  }

  get workPhoneMask(): string | null {
    const phone = (this.client.telefone || '').replace(/\D/g, '');
    if (phone.length === 10) {
      return '(##) ####-####';
    }

    if (phone.length === 11) {
      return '(##) #####-####';
    }

    return null;
  }

  get cellPhone(): string | undefined {
    if (!this.client.celular || this.client.celular === '0') {
      return undefined;
    }
    return this.client.celular;
  }

  get instagram(): string {
    const instagram = this.client.instagram || '';

    const regexp = new RegExp(
      /((?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/)?([A-Za-z0-9-_.]+)/im,
    );
    const regexpMatch = instagram.match(regexp);
    if (regexpMatch) {
      return regexpMatch[2];
    }

    return instagram;
  }

  get facebook(): string {
    const facebook = this.client.facebook || '';

    const regexp = new RegExp(/((?:(?:http|https):\/\/)?(?:www\.)?(?:facebook\.com)\/)?([A-Za-z0-9-_.]+)/im);
    const regexpMatch = facebook.match(regexp);
    if (regexpMatch) {
      return regexpMatch[2];
    }

    return facebook;
  }

  get instagramUrl(): string {
    return `https://www.instagram.com/${this.instagram}`;
  }

  get facebookUrl(): string {
    return `https://www.facebook.com/${this.facebook}`;
  }

  get isConvertedProspect(): boolean {
    return this.client.situacao === ClientStatusEnum.Converted;
  }

  get forbiddenToSetResponsibleAttendant(): boolean {
    return (this.settings.flagPermiteCadastroAtendenteChave === false || this.isConvertedProspect);
  }

  get showRedirectButton(): boolean {
    return (this.client.flagCliente === 1 || this.isConvertedProspect) && this.client.prospectCodCliente != null;
  }

  redirectToClientPage(): void {
    this.sessionService.clientType = ClientTypeEnum.Client;
    this.sessionService.activeClient = this.client;
    this.routerService.navigate({
      name: 'CrmDashboard',
      params: {
        clientId: this.client.cnpjCpf,
      },
      query: { clientType: ClientTypeEnum.Client },
    });
  }
}
